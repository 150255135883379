import statusEnum from '@/components/StatusLabel/statusEnum'

const diagnosisData = {

    'Develop': {
        'title': 'Develop (Desenvolvimento)',
        'data': [
            {
                "indicatorName": "Web Client",
                "indicatorUrl": "https://helpdesk.dev.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
            {
                "indicatorName": "Api Gateway",
                "indicatorUrl": "https://api.dev.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
            {
                "indicatorName": "Identity Microservice",
                "indicatorUrl": "https://api-identity.dev.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
            {
                "indicatorName": "Automation Microservice",
                "indicatorUrl": "https://api-automation.dev.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
            {
                "indicatorName": "Background Services",
                "indicatorUrl": "https://api-backgroundservices.dev.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
            {
                "indicatorName": "Talk Microservice",
                "indicatorUrl": "https://api-talk.dev.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
        ]
    },

    'Test': {
        'title': 'Test (Testes)',
        'data': [
            {
                "indicatorName": "Web Client",
                "indicatorUrl": "https://helpdesk.test.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
            {
                "indicatorName": "Api Gateway",
                "indicatorUrl": "https://api.test.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
            {
                "indicatorName": "Identity Microservice",
                "indicatorUrl": "https://api-identity.test.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
            {
                "indicatorName": "Automation Microservice",
                "indicatorUrl": "https://api-automation.test.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
            {
                "indicatorName": "Background Services",
                "indicatorUrl": "https://api-backgroundservices.test.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
            {
                "indicatorName": "Talk Microservice",
                "indicatorUrl": "https://api-talk.test.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
        ]
    },

    'Staging': {
        'title': 'Staging (Homologação)',
        'data': [
            {
                "indicatorName": "Web Client",
                "indicatorUrl": "https://helpdesk.staging.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
            {
                "indicatorName": "Api Gateway",
                "indicatorUrl": "https://api.staging.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
            {
                "indicatorName": "Identity Microservice",
                "indicatorUrl": "https://api-identity.staging.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
            {
                "indicatorName": "Automation Microservice",
                "indicatorUrl": "https://api-automation.staging.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
            {
                "indicatorName": "Background Services",
                "indicatorUrl": "https://api-backgroundservices.staging.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
            {
                "indicatorName": "Talk Microservice",
                "indicatorUrl": "https://api-talk.staging.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
        ]
    },

    'Production': {
        'title': 'Production (Produção)',
        'data': [
            {
                "indicatorName": "Web Client",
                "indicatorUrl": "https://helpdesk.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
            {
                "indicatorName": "Api Gateway",
                "indicatorUrl": "https://api.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
            {
                "indicatorName": "Identity Microservice",
                "indicatorUrl": "https://api-identity.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
            {
                "indicatorName": "Automation Microservice",
                "indicatorUrl": "https://api-automation.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
            {
                "indicatorName": "Background Services",
                "indicatorUrl": "https://api-backgroundservices.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
            {
                "indicatorName": "Talk Microservice",
                "indicatorUrl": "https://api-talk.talkture.com",
                "indicatorResponse": null,
                "indicatorStatus": statusEnum.ready
            },
        ]
    }

}

export default diagnosisData;