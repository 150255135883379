<template>
    <b-row>
        <b-col>

            <Box
                :title="environmentData[$props.environment].title"
                icon="journal-check"
                :collapsible="true"
                :collapsed="true"
            >

                <template #header>

                    <status-label :class="$style.statusLabel" :status="status" :count="failCount" />

                </template>

                <template #toolbox>

                    <ToolBoxButton label="Executar Diagnóstico" icon="play-circle" @click="runDiagnosis" :loading="loading" />

                </template>

                <template #nopadding>

                    <Grid :model="model" :data="data" orderBy="indicatorName">
                        
                        <template #action="slotProps">

                            <a v-if="slotProps.row.indicatorStatus != statusEnum.running" @click="runIndicatorDiagnosis(slotProps.row)" title="Executar Diagnóstico" href="javascript:void(0);">
                                <b-icon icon="play-circle" />
                            </a>

                            <Loader v-if="slotProps.row.indicatorStatus == statusEnum.running" text="" color="white" size="smallest" />

                        </template>

                    </Grid>

                </template>

            </Box>

        </b-col>
    </b-row>
</template>

<script>

import EventBus from './diagnosisEventBus';
import environmentData from './diagnosisData'
import statusEnum from '@/components/StatusLabel/statusEnum'

import Box from '@/components/Box/Box'
import ToolBoxButton from '@/components/Box/ToolBox/ToolBoxButton'
import Grid from '@/components/Grid/Grid'
import Loader from '@/components/Loader'
import StatusLabel from '@/components/StatusLabel/StatusLabel'

export default {

    name: 'DiagnosisSection',

    components: {
        Box,
        ToolBoxButton,
        Grid,
        Loader,
        StatusLabel
    },

    props: {

        environment: {
            type: String,
            default: 'Develop'
        },
        enableNotification: {
            type: Boolean,
            default: false
        }

    },

    data() {
        return {

            loading: false,
            runningCount: 0,
            status: statusEnum.ready,
            statusEnum: statusEnum,
            environmentData: environmentData,
            request: {},

            model: [
                { label: 'Status', name: 'indicatorStatus', component: 'StatusLabel' },
                { label: 'Indicador', name: 'indicatorName' },
                { label: 'URL', name: 'indicatorUrl', component: 'Link' },
                { label: 'Resposta', name: 'indicatorResponse', component: 'LongText' }
            ],

            data: [],

            actions: [
                {
                    name: 'runIndicatorDiagnosis',
                    title: 'Executar Diagnóstico',
                    icon: 'play-circle'
                }
            ]

        }
    },

    computed: {

        failCount() {

            return this.status == statusEnum.fail
                ? this.data.reduce((prev, curr) => prev + (curr.indicatorStatus == statusEnum.fail ? 1 : 0), 0)
                : null;

        }

    },

    mounted() {

        this.data = this.environmentData[this.$props.environment].data;
        
        this.runDiagnosis();

        EventBus.$on('runAllDiagnosis', this.runDiagnosis);

    },

    methods: {

        runDiagnosis() {

            this.data.forEach(indicator => {
                
                this.runIndicatorDiagnosis(indicator);

            });

        },

        runIndicatorDiagnosis(indicator) {

            // Inicializa a execução
            this.loading = true;
            this.runningCount++;
            
            if(this.status != statusEnum.running)
                this.status = statusEnum.running;

            indicator.indicatorStatus = statusEnum.running;
            indicator.indicatorResponse = null;

            // Cancela requisição anterior caso exista
            if(this.request[indicator.indicatorUrl])
                this.request[indicator.indicatorUrl].cancel("Requisição Cancelada");
                
            this.request[indicator.indicatorUrl] = this.$http.CancelToken.source();

            // Faz a requisição
            this.$http.get(indicator.indicatorUrl, { cancelToken: this.request[indicator.indicatorUrl].token })
                
                // Se tiver sucesso, registra o retorno
                .then(response => {

                    indicator.indicatorStatus = statusEnum.success;
                    indicator.indicatorResponse = response.data;

                })

                // Se tiver falha, registra o erro retornado
                .catch(error => {
                    
                    indicator.indicatorStatus = statusEnum.fail;
                    indicator.indicatorResponse = JSON.stringify(error.response || error);

                })

                // Finaliza a execução
                .finally(() => {

                    this.runningCount--;
                    this.request[indicator.indicatorUrl] = null;

                    // Se todas as requisição finalizaram, atualiza o status do ambiente
                    if(this.runningCount == 0) {

                        this.loading = false;

                        this.status = this.data.some((localIndicator) => {

                            return localIndicator.indicatorStatus == statusEnum.fail;

                        }) ? statusEnum.fail : statusEnum.success;

                        // Notificação
                        if(this.$props.enableNotification && this.status == statusEnum.fail) {

                            const notification = {
                                
                                title: `Falha: ${this.environmentData[this.$props.environment].title}`,

                                options: {
                                    body: this.data.reduce((prev, curr) => curr.indicatorStatus == statusEnum.fail ? `${prev}\n- ${curr.indicatorName}` : prev, '')
                                }

                            }
                            
                            this.$notification.show(notification.title, notification.options, {});

                        }

                    }

                });

        }

    }

}

</script>

<style lang="scss" module>

.statusLabel {

    margin-right: 2rem;

}

</style>