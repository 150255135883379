<template>
    <section>
        
        <AppBodyHeader />

        <b-container>

            <Box>

                <template #toolbox>

                    <ToolBoxButton label="Executar Diagnósticos" icon="play-circle" @click="runAllDiagnosis" />
                    <ToolBoxButton label="Develop" :icon="showDevelop ? 'check-square' : 'square'" @click="showDevelop = !showDevelop" />
                    <ToolBoxButton label="Test" :icon="showTest ? 'check-square' : 'square'" @click="showTest = !showTest" />
                    <ToolBoxButton label="Staging" :icon="showStaging ? 'check-square' : 'square'" @click="showStaging = !showStaging" />
                    <ToolBoxButton label="Production" :icon="showProduction ? 'check-square' : 'square'" @click="showProduction = !showProduction" />
                    <ToolBoxButton :label="autoRefreshLabel" :icon="autoRefresh ? 'check-square' : 'square'" @click="toggleAutoRefresh" />
                    <ToolBoxButton label="Notificação" :icon="enableNotification ? 'check-square' : 'square'" @click="toggleNotification" />

                </template>

            </Box>

            <DiagnosisSection v-if="showDevelop" environment="Develop" :enable-notification="enableNotification"></DiagnosisSection>
            <DiagnosisSection v-if="showTest" environment="Test" :enable-notification="enableNotification"></DiagnosisSection>
            <DiagnosisSection v-if="showStaging" environment="Staging" :enable-notification="enableNotification"></DiagnosisSection>
            <DiagnosisSection v-if="showProduction" environment="Production" :enable-notification="enableNotification"></DiagnosisSection>

        </b-container>

    </section>
</template>

<script>

import EventBus from './diagnosisEventBus';

import AppBodyHeader from '@/components/AppBody/AppBodyHeader'
import DiagnosisSection from './DiagnosisSection'
import Box from '@/components/Box/Box'
import ToolBoxButton from '@/components/Box/ToolBox/ToolBoxButton'

let refreshInterval = null;

export default {

    name: 'DiagnosisHome',

    components: {
        AppBodyHeader,
        DiagnosisSection,
        Box,
        ToolBoxButton
    },

    data() {
        return {

            autoRefresh: false,
            lastRefresh: null,
            timeLeftRefresh: 0,
            secondsRefresh: 60,
            showDevelop: true,
            showTest: true,
            showStaging: true,
            showProduction: true,
            enableNotification: false

        }
    },

    computed: {

        autoRefreshLabel() {

            return `Atualização Automática${this.autoRefresh ? ` (${this.timeLeftRefresh})` : ''}`;

        }

    },

    methods: {

        runAllDiagnosis() {

            this.timeLeftRefresh = this.secondsRefresh;
            this.lastRefresh = new Date();
            
            EventBus.$emit('runAllDiagnosis');

        },

        toggleAutoRefresh() {

            this.autoRefresh = !this.autoRefresh;
            this.lastRefresh = new Date();

            if(this.autoRefresh)
                this.startRefresh();
            else
                this.stopRefresh();

        },

        startRefresh() {

            this.timeLeftRefresh = this.secondsRefresh;
            refreshInterval = setInterval(this.updateRefresh, 100);

        },

        stopRefresh() {

            clearInterval(refreshInterval);

        },

        updateRefresh() {

            this.timeLeftRefresh = parseInt(this.secondsRefresh - (((new Date()) - this.lastRefresh) / 1000));

            if(this.timeLeftRefresh <= 0)
                this.runAllDiagnosis();

        },

        toggleNotification() {

            this.enableNotification = !this.enableNotification;

            if(this.enableNotification) {

                var Notification = window.Notification || window.webkitNotification;

                if(!Notification) {

                    alert('Notificação não disponível neste navegador.');

                    return;

                }

                if(Notification.permission != 'granted')

                    this.$notification.requestPermission()
                        .then(status => {

                            if(status != 'granted') {
                                
                                alert('É necessário permitir notificações no navegador.');
                                
                                this.enableNotification = false;

                            }

                        });

            }

        }

    }

}

</script>

<style lang="scss" module>



</style>